import { isMobile } from '@utils/isMobile';

export function openLivestream() {
  const href = `/live`;

  if (isMobile()) {
    return window.open(href);
  }

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const height = isSafari ? 434 : 406;

  return window.open(
    href,
    'livestream',
    `width=720,height=${height},location=no,toolbar=no,resizable=no,menubar=no`
  );
}
