import { ChromecastReceiverAvailableContext } from '@components/Chromecast/Chromecast';
import CastIcon from '@svg/cast.svg';
import { openLivestream } from '@utils/openLivestream';
import React, { useContext, useEffect, useRef } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import { loadMedia } from '../../utils/chromecast';
import styles from './LiveHeader.module.scss';

export type LiveHeaderProps = {
  streamUrl: string;
  title?: string;
};

export const LiveHeader = React.memo(function LiveHeader({
  streamUrl,
  title,
}: LiveHeaderProps) {
  const ref = useRef<HTMLVideoElement>(null);
  const mimeType = 'application/x-mpegURL';
  const chromecastAvailable = useContext(ChromecastReceiverAvailableContext);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const player = videojs(ref.current, {
      controls: false,
      autoplay: true,
      muted: true,
      fill: true,
      sources: [
        {
          src: streamUrl,
          type: mimeType,
        },
      ],
    });

    return () => {
      player.dispose();
    };
  }, [streamUrl]);

  return (
    <>
      <div className={styles.LiveHeader}>
        <div className={styles.LiveHeaderVideo}>
          <video ref={ref} muted playsInline></video>
        </div>

        <div
          role="button"
          className={styles.LiveHeaderOverlay}
          onClick={openLivestream}
          title="Afspelen in nieuw venster"
        >
          {title && <h2 className={styles.LiveHeaderTitle}>{title}</h2>}

          {chromecastAvailable && (
            <button
              className={styles.LiveHeaderChromecastButton}
              title="Afspelen met Chromecast"
              onClick={(e) => {
                e.stopPropagation();
                loadMedia(streamUrl, mimeType);
              }}
            >
              <CastIcon />
            </button>
          )}
        </div>
      </div>
    </>
  );
});
